<template>
  <div>
    <skew-image src="img/aerial-view-of-road-between-green-grass-field-3635300.jpg"/>
    <container>
      <h1>The simple way of passing the swiss 🇨🇭 driving test!</h1>
    </container>
    <f-button v-on:click.native="next()">Iniziamo</f-button>
  </div>
</template>

<style scoped>
h1 {
  font-size:4.5rem;
  line-height: 1.15;
  margin: 1.15rem 0px 1.15rem 0px;
  font-weight: bolder;
}
</style>

<script>
import FButton from '@/components/FButton.vue';
import SkewImage from '@/components/SkewImage.vue';
import Container from '@/components/Container.vue';

export default {
  name: 'Index',
  components: { FButton, SkewImage, Container },
  methods: {
    next() {
      this.$router.push('/profile');
    },
  },
};
</script>
